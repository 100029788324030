<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/index_a.png" alt="" width="28">
        <h2>我的审批</h2>
      </div>
      <el-button size="small" @click="$router.go(-1)">返回上一页</el-button>
    </div>
    <div class="detail">
      <h4 class="title">基本信息</h4>
      <div class="desc">
        <span>课题编号：{{ activeInfo.number }}</span>
        <span>申请日期：{{ activeInfo.startTime | formatDay }}</span>
        <p v-if="isProd==1">
          <span>负责人：{{ activeInfo.detail.director }}</span>
          <span>负责人电话：{{ activeInfo.detail.contact }}</span>
          <span>负责人邮箱：{{ activeInfo.detail.email }}</span>
        </p>
        <p>实验名称：{{ activeInfo.name }}</p>
        <p>实验摘要：{{ activeInfo.detail.summary }}</p>
      </div>
      <h4 class="title">{{ isProd == 0 ? '实验' : '合笼' }}成员</h4>
      <div class="desc">
        <span>负责人：{{ memberPrincipal.name }}</span>
        <span>负责人电话：{{ memberPrincipal.phone }}</span>
        <span>负责人邮箱：{{ memberPrincipal.email }}</span>
      </div>
      <el-table
          v-if="memberList"
          :data="memberList"
          border
          class="tableMember"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
      >
        <el-table-column label="项目组成员姓名" width="180" prop="name">
        </el-table-column>
        <el-table-column label="相关专业证书编号" min-width="100" show-overflow-tooltip prop="certNum">
        </el-table-column>
        <el-table-column label="工作内容" show-overflow-tooltip prop="jobContent">
        </el-table-column>
        <el-table-column label="电话" prop="phone">
        </el-table-column>
        <el-table-column label="邮箱" prop="email">
        </el-table-column>
      </el-table>
      <h4 class="title">{{ isProd == 0 ? '实验' : '课题' }}动物</h4>
      <template v-if="isProd==1">
        <div class="desc">
          <p>
            <template v-if="activeInfo.isLabAni==1">
              <span>来源：实验室</span>
            </template>
            <template v-if="activeInfo.isLabAni!=1&&activeInfo.aniOrder.aniSupply">
              <span>来源：{{ activeInfo.aniOrder.aniSupply.supplier }}</span>
              <span>许可证：{{ activeInfo.aniOrder.aniSupply.prodCert }}</span>
            </template>
            <span>动物品系：{{ activeInfo.strain }}</span>
          </p>
          <p>
            <span>笼舍数量：{{ activeInfo.cageNum }}</span>
            <span>等级：{{ activeInfo.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级' }}</span>
            <span>是否特殊基因合笼：{{ activeInfo.aniOrder.isGene == 1 ? '是' : '否' }}</span>
          </p>
          <p v-if="activeInfo.aniOrder.isGene==1">基因类型：{{ activeInfo.aniOrder.geneKeys }}</p>
        </div>
        <el-table
            :data="activeInfo.aniOrder.aniOrderDetailList"
            border
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
        >
          <el-table-column label="基因类型" v-if="activeInfo.aniOrder.isGene==1">
            <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
              <template slot-scope="scope">
                {{ scope.row.typeList[ind].type ? scope.row.typeList[ind].type : '' }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="性别">
            <template slot-scope="scope">
              {{ scope.row.gender == 0 ? '♀' : '♂' }}
            </template>
          </el-table-column>
          <el-table-column label="数量" show-overflow-tooltip prop="count">
          </el-table-column>
          <el-table-column label="体重(g)" prop="weight">
          </el-table-column>
          <el-table-column label="周龄" prop="weekDays">
          </el-table-column>
        </el-table>
      </template>
      <template v-else>
        <div class="desc">
          <p>
            <span>品系：{{ activeInfo.strain }}</span>
            <span>来源：{{ activeInfo.aniOrder.aniSupply.supplier }}</span>
            <span>许可证：{{ activeInfo.aniOrder.aniSupply.prodCert }}</span>
          </p>
          <p>
            <span>需要笼舍数量：{{ activeInfo.cageNum }}</span>
            <span>申请笼舍数量：{{ activeInfo.detail.applyCageNum }}</span>
          </p>
          <p>
            <span>雌性周龄：{{ female.weekDays }}周</span>
            <span>雌性体重：{{ female.weight }}g</span>
            <span>雌性数量：{{ female.count }}</span>
          </p>
          <p>
            <span>雄性周龄：{{ male.weekDays }}周</span>
            <span>雄性体重：{{ male.weight }}g</span>
            <span>雄性数量：{{ male.count }}</span>
          </p>
          <p>等级：{{ activeInfo.aniOrder.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级' }}</p>
        </div>
      </template>
      <h4 class="title">实验时间</h4>
      <div class="desc">
        <span>计划开始时间：{{ activeInfo.startTime  | formatDay }}</span>
        <span v-if="isProd==0">计划结束时间：{{ activeInfo.period | formatDay }}</span>
      </div>
      <template>
        <h4 class="title">实验备注</h4>
        <div class="desc">
          <p>实验备注：{{ activeInfo.detail.remark }}</p>
        </div>
      </template>
      <h4 class="formTit title">方案审批</h4>
      <template v-if="result==1">
        <el-form ref="approvalForm" size="small" :rules="rules" :model="approvalForm" class="addForm"
                 label-suffix=":"
                 label-width="90px">
          <el-form-item label="审批结果">
            <el-radio-group v-model="approvalForm.approveResult" class="radioGroup">
              <el-radio :label="2">通过</el-radio>
              <el-radio :label="3">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="审批意见" :prop="approvalForm.approveResult==3?'remark':''">
            <el-input v-model="approvalForm.remark" placeholder="请输入审批意见" type="textarea" rows="3"></el-input>
          </el-form-item>
          <template v-if="approvalForm.approveResult==2&&detailApproval.processSort==detailApproval.totalApproveLevel">
            <el-form-item label="笼舍编号" class="cageNum">
              <div v-for="(item,ind) in approvalForm.cageList" class="cuddles flex">
                <el-form-item :prop="`cageList.${ind}.number`" :rules="rules.number">
                  <el-input size="small" :placeholder="(ind+1)+' 编号'" v-model="item.number" clearable>
                    <i slot="prefix">笼舍</i>
                  </el-input>
                </el-form-item>
                <el-form-item :prop="`cageList.${ind}.frameNo`" :rules="rules.frameNo">
                  <el-input size="small" :placeholder="(ind+1)+' 编号'" v-model="item.frameNo" clearable>
                    <i slot="prefix">笼架</i>
                  </el-input>
                </el-form-item>
              </div>
              <div style="clear: both"></div>
            </el-form-item>
          </template>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="submitApproval('approvalForm')">审批完成</el-button>
          </div>
        </el-form>
      </template>
      <template v-else>
        <div class="desc">
          <p>审批结果：{{ detailApproval.approveResult == 2 ? '通过' : '拒绝' }}</p>
          <p>审批意见：{{ detailApproval.remark }}</p>
          <div class="cageList"
               v-if="detailApproval.processSort==detailApproval.totalApproveLevel&&detailApproval.cageList&&detailApproval.cageList.length>0">
            <span>笼舍编号： </span>
            <div>
              <p v-for="(item, ind ) in detailApproval.cageList">
                笼舍编号：{{ item.number }}
                笼架编号：{{ item.frameNo }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "approvalDetail",
  data() {
    return {
      isProd: this.$route.query.isProd,//0实验课题
      subId: this.$route.query.subId,
      activeInfo: {
        detail: {},
        aniOrder: {aniSupply: {}},
      },
      memberList: [],
      memberPrincipal: {},
      typeList: [],
      // 审批
      approvalForm: {
        id: this.$route.query.id,
        approveResult: 2,
        remark: "",
        cageList: [],
      },
      result: this.$route.query.result,
      rules: {
        remark: [
          {required: true, message: '请输入审批意见', trigger: 'blur'},
        ],
        number: [
          {required: true, message: '请输入笼舍编号', trigger: 'blur'},
        ],
        frameNo: [
          {required: true, message: '请输入笼架编号', trigger: 'blur'},
        ]
      },
      detailApproval: {
        totalApproveLevel: null,
        processSort: null,
        approveResult: null,
        result: ""
      },
      // 雌性
      female: {},
      // 雄性
      male: {},
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  mounted() {
    let that = this;
    that.$get("/subject/detail/" + this.subId).then((res) => {
      if (res.status == 200) {
        that.activeInfo = res.data

        let cageNUm;

        // 生产课题
        if (this.isProd == 1) {
          res.data.aniOrder.aniOrderDetailList.forEach(obj => {
            obj.typeList = JSON.parse(obj.gene)
          })
          // 赋值typeList
          let arr = this.activeInfo.aniOrder.geneKeys.split(',')
          arr.forEach(item => {
            this.typeList.push({
              title: item
            })
          })
          // 笼舍数量
          cageNUm = this.activeInfo.cageNum
        }
        // 实验课题
        if (this.isProd == 0) {
          // 雌雄性数量
          res.data.aniOrder.aniOrderDetailList.forEach(obj => {
            if (obj.gender == 0) {
              this.female = obj
            } else {
              this.male = obj
            }
          })
          // 笼舍数量
          cageNUm = this.activeInfo.detail.applyCageNum
        }
        // 成员
        res.data.memberList.forEach(item => {
          if (item.isPrincipal == 1) {
            this.memberPrincipal = item
          } else {
            this.memberList.push(item)
          }
        })
        // 笼舍编号
        for (let i = 0; i < cageNUm; i++) {
          this.approvalForm.cageList.push({number: '', frameNo: ''})
        }
      }
    }).catch(() => {
      this.$message.error('获取数据失败')
    })
    this.getFlow()
  },
  methods: {
    // 提交审批
    submitApproval(approvalForm) {
      this.$refs[approvalForm].validate((valid) => {
        if (valid) {
          this.$putJson("/subject/approval", this.approvalForm).then((res) => {
            this.$message.success('审批完成')
            this.$router.go(-1)
          }).catch(() => {
            this.$message.error('提交失败')
          })
        } else {
          return false;
        }
      });
    },
    getFlow() {
      let that = this;
      that.$get("/subject/approval/result/" + this.approvalForm.id).then(res => {
        this.detailApproval = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/exper/approval";

</style>
